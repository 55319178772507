import { useQuery } from "react-query";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Link as MuiLink,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import { useRequireParams } from "@/utils";
import { getBranch } from "@/services/branch";

import { BranchDetail } from "./BranchDetail";

import type { TabsProps } from "@mui/material";

const QUERY_KEY = "getBranch";

export function BranchDetailPage() {
  const { id, branchId } = useRequireParams(["id", "branchId"]);
  const location = useLocation();
  const navigate = useNavigate();
  const { data, isFetching } = useQuery([QUERY_KEY], () => getBranch(branchId));

  const path = location?.pathname ?? "";
  const isExpiredPath = path.includes("expired");
  const expiredPath = isExpiredPath ? "/expired" : "";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 4.5 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/fitnesses">
          รายชื่อฟิตเนส
        </MuiLink>
        <MuiLink
          component={Link}
          color="text.secondary"
          to={`/fitnesses${isExpiredPath ? "/expired" : ""}`}
        >
          {isExpiredPath ? "หมดอายุ" : "ใช้งานอยู่"}
        </MuiLink>
        <MuiLink
          component={Link}
          color="text.secondary"
          to={`/fitnesses/${id}`}
        >
          รายละเอียดฟิตเนส
        </MuiLink>
        <Typography color="text.primary">รายละเอียดสาขา</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {!isFetching && data ? (
          <CardContent>
            <BranchDetail data={data} fetchKeys={[QUERY_KEY]} />
          </CardContent>
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={306}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
      <Tabs value={path} onChange={onChangeTab} sx={{ mb: 3 }}>
        <Tab
          label="ข้อมูลประตู"
          value={`/fitnesses${expiredPath}/${id}/branches/${branchId}`}
          sx={{ width: 180 }}
        />
        <Tab
          label="สิทธิ์การใช้งานประตู"
          value={`/fitnesses${expiredPath}/${id}/branches/${branchId}/permission-doors`}
          sx={{ width: 180 }}
        />
      </Tabs>
      <Outlet />
    </Box>
  );
}
