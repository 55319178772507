import { Autocomplete as MuiAutocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import * as yup from "yup";
import { useQuery } from "react-query";

import { getFitnessPlans } from "@/services/fitnessPlan";

import type {
  AutocompleteProps as MuiAutocompleteProps,
  TextFieldProps,
} from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

const QUERY_KEY = "fitnessPlanAutocomplete";

export type FitnessPlanAutocompleteValue = {
  id: number;
  name: string;
  maxMember: number;
  price: number;
};

export type FitnessPlanAutocompleteProps = {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<FitnessPlanAutocompleteValue, false, false, false>,
  "renderInput" | "options"
>;

export function FitnessPlanAutocomplete({
  label,
  TextFieldProps,
  ...props
}: FitnessPlanAutocompleteProps) {
  const { data: raw, isFetching } = useQuery([QUERY_KEY], () =>
    getFitnessPlans()
  );

  const options = raw?.map((plan) => plan) ?? [];

  return (
    <MuiAutocomplete
      options={options}
      loading={isFetching}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormFitnessPlanAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    FitnessPlanAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <FitnessPlanAutocomplete
      value={field.value ?? null}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}

const schema = yup
  .object({
    id: yup.number().required(),
    name: yup.string().required(),
    maxMember: yup.number().required(),
    price: yup.number().required(),
  })
  .default(null) // fix yup not validate when value is undefined
  .nullable();

FitnessPlanAutocomplete.schema = schema;
