import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link as MuiLink,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { getFitness } from "@/services/fitness";
import { getDocumentTitle, useRequireParams } from "@/utils";

import { FitnessDetail } from "./FitnessDetail";

import type { TabsProps } from "@mui/material";

export const GET_FITNESS_QUERY_KEY = "getFitness";

export function FitnessDetailPage() {
  const { id } = useRequireParams(["id"]);
  const location = useLocation();
  const navigate = useNavigate();

  const path = location?.pathname ?? "";
  const isExpiredPath = path.includes("expired");
  const expiredPath = isExpiredPath ? "/expired" : "";

  const { data, isFetching } = useQuery([GET_FITNESS_QUERY_KEY], () =>
    getFitness(id)
  );

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Manage Fitness")}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 4.5 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/fitnesses">
          รายชื่อฟิตเนส
        </MuiLink>
        <MuiLink
          component={Link}
          color="text.secondary"
          to={`/fitnesses${expiredPath}`}
        >
          {isExpiredPath ? "หมดอายุ" : "ใช้งานอยู่"}
        </MuiLink>
        <Typography color="text.primary">รายละเอียดฟิตเนส</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {!isFetching && data ? (
          <FitnessDetail data={data} fetchKeys={[GET_FITNESS_QUERY_KEY]} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={436}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
      <Tabs value={path} onChange={onChangeTab} sx={{ mb: 3 }}>
        <Tab
          label="สาขา"
          value={`/fitnesses${expiredPath}/${id}`}
          sx={{ width: 180 }}
        />
        <Tab
          label="บัตรสมาชิก"
          value={`/fitnesses${expiredPath}/${id}/member-card`}
          sx={{ width: 180 }}
        />
        <Tab
          label="ประวัติการขาย"
          sx={{ width: 180 }}
          value={`/fitnesses${expiredPath}/${id}/transactions`}
        />
        <Tab
          label="ตั้งค่าสิทธิ์การใช้งาน"
          sx={{ width: 180 }}
          value={`/fitnesses${expiredPath}/${id}/permission-group`}
        />
      </Tabs>
      <Outlet />
    </Box>
  );
}
