import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { QueryTextField } from "@/components/QueryTextField";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { DataTableBody } from "@/components/DataTableBody";
import { ItemsTotal } from "@/components/ItemsTotal";
import { mapOptional, pickListTableParams } from "@/utils";
import { formatBlogStatus } from "@/formatter";
import { getBlogs } from "@/services/blog";
import { BlogStatus } from "@/models";

import { BlogTableRow, BlogTableRowHeader } from "./BlogTableRow";
import { AddBlogDialog } from "./AddBlogDialog";
import { RemoveBlogDialog } from "../../components/RemoveBlogDialog";
import { EditBlogDialog } from "../../components/EditBlogDialob";

const QUERY_KEY = "blogs";
export function BlogListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getBlogs(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total ?? 0;

  const status = searchParams.get("status") as BlogStatus | undefined;
  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";

  const editData = data.find((item) => item.id === +id);

  const setStatus = (s: BlogStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  function add() {
    searchParams.set("dialog", "blog-add");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    open: dialog === "blog-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const editDialog = {
    open: typeof editData !== "undefined" && dialog === "blog-edit",
    id,
    onClose: onCloseDialog,
    data: editData,
    fetchKeys: [QUERY_KEY],
  };

  const removeDialog = {
    open: dialog === "blog-remove",
    id,
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            mb={3}
          >
            <QueryTextField sx={{ width: 600 }} placeholder="ค้นหาหัวข้อ" />
            <Stack direction="row" gap={3}>
              <ButtonGroup variant="contained" size="large" color="inherit">
                {[null, BlogStatus.Published, BlogStatus.Draft].map((s) => (
                  <Button
                    key={s}
                    onClick={setStatus(s)}
                    sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
                  >
                    {mapOptional(s, formatBlogStatus) ?? "ทั้งหมด"}
                  </Button>
                ))}
              </ButtonGroup>
              <Button variant="contained" size="medium" onClick={add}>
                สร้างบทความ
              </Button>
            </Stack>
          </Stack>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <BlogTableRowHeader />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <BlogTableRow
                    key={item.id}
                    data={item}
                    fetchKeys={[QUERY_KEY]}
                  />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddBlogDialog {...addDialog} />
      <EditBlogDialog {...editDialog} />
      <RemoveBlogDialog {...removeDialog} />
    </Box>
  );
}
