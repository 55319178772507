import {
  Box,
  Card,
  CardContent,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
  MenuItem,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { getFitnessActiveList } from "@/services/fitness";
import { ItemsTotal } from "@/components/ItemsTotal";
import { SearchParamsSelect } from "@/components/Select";

import { ActiveTableRow, ActiveTableRowHeader } from "./ActiveTableRow";
import { AddFitnessDialog } from "./AddFitnessDialog";

const QUERY_KEY = "fitnesses-active";

export function ActiveListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const dialog = searchParams.get("dialog");

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getFitnessActiveList(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  const planOptions = [
    { label: "ทั้งหมด", value: 0 },
    { label: "ฟรี", value: 1 },
    { label: "สแตนดาร์ด", value: 2 },
    { label: "พรีเมียม", value: 3 },
    { label: "แอดวานซ์", value: 4 },
    { label: "มาสเตอร์", value: 5 },
    { label: "องค์กร", value: 6 },
  ];

  function add() {
    searchParams.set("dialog", "fitness-add");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const addMemberDialog = {
    open: dialog === "fitness-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack>
            <Stack direction="row" alignItems="center" gap={2} mb={5}>
              <QueryTextField
                sx={{ width: 600 }}
                placeholder="ค้นหารายชื่อฟิตเนส"
              />
              <SearchParamsSelect
                sx={{ width: 200 }}
                name="plan"
                label="Package ปัจจุบัน"
                placeholder="ทั้งหมด"
              >
                {planOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </SearchParamsSelect>
              <Button
                variant="contained"
                size="large"
                sx={{ ml: "auto" }}
                onClick={add}
              >
                เพิ่มฟิตเนส
              </Button>
            </Stack>
            <ItemsTotal count={total} />
            <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <ActiveTableRowHeader />
                </TableHead>
                <DataTableBody loading={isFetching} data={data}>
                  {data.map((item) => (
                    <ActiveTableRow key={item.id} data={item} />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
        <AddFitnessDialog {...addMemberDialog} />
      </Card>
    </Box>
  );
}
