import {
  Box,
  Card,
  CardContent,
  Stack,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { getFitnessExpiredList } from "@/services/fitness";
import { ItemsTotal } from "@/components/ItemsTotal";

import { ExpiredTableRow, ExpiredTableRowHeader } from "./ExpiredTableRow";

const QUERY_KEY = "fitnesses-expired";

export function ExpiredListPage() {
  const [searchParams] = useSearchParams();
  const query = searchParams.toString();

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getFitnessExpiredList(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack>
            <Stack direction="row" alignItems="center" gap={2} mb={5}>
              <QueryTextField
                sx={{ width: 600 }}
                placeholder="ค้นหารายชื่อฟิตเนส"
              />
            </Stack>
            <ItemsTotal count={total} />
            <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <ExpiredTableRowHeader />
                </TableHead>
                <DataTableBody loading={isFetching} data={data}>
                  {data.map((item) => (
                    <ExpiredTableRow key={item.id} data={item} />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
