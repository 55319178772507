import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  TextField,
} from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";

import { getDoors } from "@/services/door";

import type {
  AutocompleteProps as MuiAutocompleteProps,
  TextFieldProps,
} from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

export type DoorAutocompleteValue = { id: number; name: string };

export type DoorAutocompleteProps = {
  branchId: string;
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<DoorAutocompleteValue, true, false, false>,
  "renderInput" | "options"
>;

export function DoorAutocomplete({
  branchId,
  label,
  TextFieldProps,
  ...props
}: DoorAutocompleteProps) {
  const { data, isFetching } = useQuery(["getDoorAutocomplete"], () =>
    getDoors(branchId)
  );

  const options = data?.map(({ id, name }) => ({ id, name })) ?? [];

  return (
    <MuiAutocomplete
      multiple
      disableCloseOnSelect
      options={options}
      loading={isFetching}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.id}>
            <Checkbox checked={selected} />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormDoorAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    DoorAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <DoorAutocomplete
      value={field.value}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}
