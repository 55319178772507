import {
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import { DataTableBody } from "@/components/DataTableBody";
import { useRequireParams } from "@/utils";
import { getPermissionDoors } from "@/services/permissionDoor";

import {
  PermissionDoorTableRow,
  PermissionDoorTableRowHeader,
} from "./PermissionDoorTableRow";
import { PermissionDoorsDialog } from "./PermissionDoorsDialog";
import { EditPermissionDoorDialog } from "./EditPermissionDoorDialog";

const QUERY_KEY = "getPermissionDoors";

export function PermissionDoorList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { branchId } = useRequireParams(["branchId"]);

  const { data: raw, isFetching } = useQuery([QUERY_KEY], () =>
    getPermissionDoors(branchId)
  );

  const dialog = searchParams.get("dialog");
  const permissionGroupId = searchParams.get("id");

  const data = raw ?? [];

  const selected = data.find(({ id }) => id.toString() === permissionGroupId);

  const onCloseDialog = () => {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  };

  const permissionDoorsDialog = {
    open: dialog === "permission-door-see-more",
    onClose: onCloseDialog,
    doors: selected?.doors ?? [],
  };

  const editPermissionDoorDialog = {
    branchId,
    open: dialog === "permission-door-edit",
    onClose: onCloseDialog,
    data: selected,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Stack gap={5}>
          <Typography variant="h6">สิทธิ์การใช้งานประตู</Typography>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <PermissionDoorTableRowHeader />
              </TableHead>
              <DataTableBody
                loading={isFetching}
                data={data}
                searchKeys={["query", "status"]}
              >
                {data.map((item) => (
                  <PermissionDoorTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </Stack>
      </CardContent>
      <PermissionDoorsDialog {...permissionDoorsDialog} />
      <EditPermissionDoorDialog {...editPermissionDoorDialog} />
    </Card>
  );
}
