import { useQuery } from "react-query";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

import { useRequireParams } from "@/utils";
import { getBlog } from "@/services/blog";

import { BlogDetail } from "./BlogDetail";

const QUERY_KEY = "getBlog";

export function BlogDetailPage() {
  const { id } = useRequireParams(["id"]);
  const { data, isFetching } = useQuery([QUERY_KEY], () => getBlog(id));

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 4.5 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/blogs">
          บทความ
        </MuiLink>
        <Typography color="text.primary">รายละเอียดบทความ</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {!isFetching && data ? (
          <CardContent>
            <BlogDetail data={data} fetchKeys={[QUERY_KEY]} />
          </CardContent>
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={306}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
    </Box>
  );
}
