import {
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { getBranches } from "@/services/branch";
import { DataTableBody } from "@/components/DataTableBody";
import { useRequireParams } from "@/utils";

import { BranchTableRow, BranchTableRowHeader } from "./BranchTableRow";
import { AddBranchDialog } from "./AddBranchDialog";

const QUERY_KEY = "getBranches";

export function BranchList() {
  const { id } = useRequireParams(["id"]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: raw, isFetching } = useQuery([QUERY_KEY], () =>
    getBranches(id)
  );

  const dialog = searchParams.get("dialog");

  function add() {
    searchParams.set("dialog", "branch-add");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    fitnessCenterId: id,
    open: dialog === "branch-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const data = raw ?? [];

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mb={5}
        >
          <Typography variant="h6">สาขา</Typography>
          <Stack direction="row">
            <Button variant="contained" size="large" onClick={add}>
              เพิ่มสาขา
            </Button>
          </Stack>
        </Stack>
        <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
          <Table stickyHeader>
            <TableHead>
              <BranchTableRowHeader />
            </TableHead>
            <DataTableBody
              loading={isFetching}
              data={data}
              searchKeys={["query", "status"]}
            >
              {data.map((item) => (
                <BranchTableRow key={item.id} data={item} />
              ))}
            </DataTableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <AddBranchDialog {...addDialog} />
    </Card>
  );
}
