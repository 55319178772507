import {
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { DataTableBody } from "@/components/DataTableBody";
import { useRequireParams } from "@/utils";
import { getDoors } from "@/services/door";

import { DoorTableRowHeader, DoorTableRow } from "./DoorTableRow";
import { AddDoorDialog } from "./AddDoorDialog";
import { EditDoorDialog } from "./EditDoorDialog";
import { RemoveDoorDialog } from "./RemoveDoorDialog";
import { AlertRemoveDoorDialog } from "./AlertRemoveDoorDialog";

const QUERY_KEY = "getDoors";

export function DoorList() {
  const { branchId } = useRequireParams(["branchId"]);
  const [searchParams, setSearchParams] = useSearchParams();

  const dialog = searchParams.get("dialog");
  const doorId = searchParams.get("id");

  const { data: raw, isFetching } = useQuery([QUERY_KEY], () =>
    getDoors(branchId)
  );

  function add() {
    searchParams.set("dialog", "door-add");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  const data = raw ?? [];
  const selected = data.find(({ id }) => id.toString() === doorId);
  const isUsed = !!selected && selected.permissionCount > 0;

  const addDialog = {
    branchId,
    open: dialog === "door-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const editDialog = {
    branchId,
    data: selected,
    open: dialog === "door-edit",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const removeDialog = {
    branchId,
    data: selected,
    open: dialog === "door-remove" && !isUsed,
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const alertDialog = {
    open: dialog === "door-remove" && isUsed,
    data: selected,
    onClose: onCloseDialog,
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mb={5}
        >
          <Typography variant="h6">ข้อมูลประตู</Typography>
          <Button variant="contained" size="large" onClick={add}>
            เพิ่มประตู
          </Button>
        </Stack>
        <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
          <Table stickyHeader>
            <TableHead>
              <DoorTableRowHeader />
            </TableHead>
            <DataTableBody
              loading={isFetching}
              data={data}
              searchKeys={["query", "status"]}
            >
              {data.map((item) => (
                <DoorTableRow key={item.id} data={item} />
              ))}
            </DataTableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <AddDoorDialog {...addDialog} />
      <EditDoorDialog {...editDialog} />
      <RemoveDoorDialog {...removeDialog} />
      <AlertRemoveDoorDialog {...alertDialog} />
    </Card>
  );
}
